<template>
  <div class="dashboard">
    <va-card :title="$t('dashboard.table.title') + ` - ${filteredData.length} operações encontradas`">
      <div class="row align--center mb-1">
        <div class="flex xs12 sm6">
          <va-input
            class="ma-0"
            :value="term"
            :placeholder="$t('tables.searchByName')"
            @input="search"
            removable
          >
            <va-icon name="fa fa-search" slot="prepend" />
          </va-input>
        </div>

        <div class="flex xs12 md3 offset--md3">
          <va-select
            :options="perPageOptions"
            v-model="perPage"
            label="Itens por página"
          />
        </div>

      </div>

      <va-data-table
        :per-page="perPage"
        :fields="mode ? detailedFields : fields"
        :data="filteredData"
        :loading="loading"
        hoverable
      >
        <template slot="razaoSocial" slot-scope="props">
          <a :href="`https://zxvf.tutudigital.com.br/tomadores/${props.rowData.tomadorId}/dossie1`" target="_blank">
            {{props.rowData.razaoSocial}}
          </a>
        </template>
        <template slot="icon">
          <va-icon name="fa fa-user" color="secondary" />
        </template>
        <template v-slot:starred="props">
          <va-icon
            v-if="props.rowData.starred"
            name="fa fa-star"
            color="warning"
          />
        </template>
        <template v-slot:status="props">
          {{ props.rowData.status }}
        </template>

        <template v-slot:actions="props">
          <va-button
            small
            outline
            color="success"
            icon="fa fa-check"
            class="ma-0"
            @click="resolveUser(props.rowData)"
          >
            {{ $t('dashboard.table.resolve') }}
          </va-button>
        </template>
      </va-data-table>

    </va-card>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import negativacoesService from '../../services/Cobranca/NegativacoesService'

export default {
  data () {
    return {
      operacoes: [],
      perPage: 15,
      perPageOptions: ['5', '10', '15', '20', '25', '30', '35', '40', '50', '75', '100', '125', '150', '200', '300', '400', '500'],
      loading: false,
      term: null,
      mode: 0,
    }
  },
  computed: {
    fields () {
      return [{
        name: 'id',
        title: 'EmprestimoId',
      }, {
        name: '__slot:razaoSocial',
        title: 'Razão Social',
      }, {
        name: 'acordoId',
        title: 'AcordoId',
      },
      {
        name: 'statusAcordo',
        title: 'Status Acordo',
      },
      {
        name: 'diasVencidoPrimeiraParcela',
        title: 'Dias Vencido',
      },
      {
        name: 'negativado',
        title: 'CNPJ Neg.',
      },
      {
        name: 'todosAvalistasNegativados',
        title: 'Todos Avalistas Neg.',
      },
      {
        name: 'algumAvalistaNegativado',
        title: 'Algum Avalista Neg.',
      },
      {
        name: 'enviadoParaJuridico',
        title: 'Enviado para JURÍDICO',
      },
      {
        name: 'numeroProcesso',
        title: 'Número Processo',
      }]
    },
    modeOptions () {
      return [{
        value: 0,
        label: this.$t('dashboard.table.brief'),
      }]
    },
    filteredData () {
      if (!this.term || this.term.length < 1) {
        return this.operacoes
      }

      return this.operacoes.filter(item => {
        return item.razaoSocial.toLowerCase().startsWith(this.term.toLowerCase())
      })
    },
  },
  methods: {
    getStatusColor (status) {
      if (status === 'paid') {
        return 'success'
      }

      if (status === 'processing') {
        return 'info'
      }

      return 'danger'
    },
    search: debounce(function (term) {
      this.term = term
    }, 400),
  },
  async mounted () {
    this.operacoes = (await negativacoesService.seraoNegativados()).data.data
  },
}
</script>
